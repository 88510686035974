import { Box, Button, Flex, Image, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import TheGameLogo from '../../assets/TheGameLogo'
import { environment } from '../../environments/environment'
import { isLocalEnv } from '../helpers/is-local-env'
import { useGetPresignedUrl } from '../hooks/useGetPresignedUrl'
import { useUploadPublicAssetLocal } from '../hooks/useUploadPublicAssetLocal'
import { FileControl } from './FileControl'
import { MdPublishedWithChanges } from 'react-icons/md'
import { FeaturedImageUpload } from './FeaturedImageUpload'

export function OfficialPartnerUpload() {
    const [uploadFile] = useUploadPublicAssetLocal()
    const [getSignedUrl] = useGetPresignedUrl()
    const [selectedImage, setSelectedImage] = useState<File | null>(null)
    const [cacheBuster, setCachebuster] = useState(Date.now())
    const toast = useToast()

    const onImageApply = async (file: File | null) => {
        if (file === null) return

        const filename = 'officialpartner.png'
        const contentType = file.type

        setSelectedImage(file)

        if (isLocalEnv()) {
            await uploadFile({
                file,
                filename,
            })
            setCachebuster(Date.now())
        } else {
            try {
                const response = await getSignedUrl({ filename, contentType })

                if (!response) {
                    throw Error('No response from server')
                }

                const headers = new Headers()
                headers.append('Cache-Control', `public, max-age=120`)

                const result = await fetch(response.url, {
                    method: 'PUT',
                    body: file,
                    headers,
                })

                if (result.status !== 200) {
                    toast({
                        title: 'Oh no something went wrong!',
                        description:
                            'There was an error uploading the file - please check the console and contact the dev team if the problem persists',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                } else {
                    toast({
                        title: 'Success',
                        description: `Official Partner logo has been updated`,
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    })
                    setCachebuster(Date.now())
                }
            } catch {
                console.error('Couldnt upload file / configure season')
            }
        }
    }

    return (
        <Box mt="10" mb="10">
            <Text fontSize={'xx-large'} fontWeight="bold">
                Images & Assets
            </Text>
            <Text fontSize={'sm'}>App wide images and assets management</Text>
            <Text fontSize={'x-large'} fontWeight="bold" marginTop={8}>
                Official Partner Logo
            </Text>
            <Text fontSize={'sm'}>.png format, 100x100px</Text>
            <Flex
                mt="4"
                width={450}
                height={120}
                border="1px #E2E8F0 solid"
                p="8"
                borderRadius="5"
                alignItems="center"
                justifyContent="space-between"
            >
                <Flex>
                    <Box
                        width={100}
                        marginRight={25}
                        border="1px #E2E8F0 solid"
                    >
                        <TheGameLogo />
                    </Box>
                    <Image
                        src={
                            selectedImage
                                ? URL.createObjectURL(selectedImage)
                                : isLocalEnv()
                                ? `${environment.apiGatewayUrl}/public-assets/officialpartner.png#${cacheBuster}`
                                : `${environment.frontendUrl}/public-assets/officialpartner.png#${cacheBuster}`
                        }
                        width={100}
                        objectFit="contain"
                        border={`1px ${
                            selectedImage ? 'orange' : 'lime'
                        } solid`}
                    />
                </Flex>
                <Flex direction="column">
                    <FileControl
                        buttonLabel="Upload"
                        onSelect={(file) => setSelectedImage(file)}
                        accept=".png"
                    />
                    <Button
                        marginTop={2}
                        colorScheme="blue"
                        onClick={() => onImageApply(selectedImage)}
                        disabled={selectedImage === null}
                        leftIcon={<MdPublishedWithChanges />}
                    >
                        Apply
                    </Button>
                </Flex>
            </Flex>
            <Text fontSize={'x-large'} fontWeight="bold" marginTop={8}>
                Featured Image
            </Text>
            <Text fontSize={'sm'}>.png format, 838x838px</Text>
            <FeaturedImageUpload
                fileName={'featuredimage.png'}
            ></FeaturedImageUpload>
            <Text fontSize={'x-large'} fontWeight="bold" marginTop={8}>
                Featured Image (small)
            </Text>
            <Text fontSize={'sm'}>.png format, 375x290px</Text>
            <FeaturedImageUpload
                fileName={'featuredimage-small.png'}
            ></FeaturedImageUpload>
        </Box>
    )
}
